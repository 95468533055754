import $ from 'jquery'
import Swiper from 'swiper/bundle'
import 'swiper/swiper-bundle.css'
class GridIconSlider extends window.HTMLDivElement {
  constructor (...args) {
    const self = super(...args)
    self.init()
    return self
  }

  init () {
    this.$ = $(this)
    this.props = this.getInitialProps()
    this.resolveElements()
    this.bindFunctions()
  }

  getInitialProps () {
    let data = {}
    try {
      data = JSON.parse($('script[type="application/json"]', this).text())
    } catch (e) {}
    return data
  }

  bindFunctions () {
  }

  resolveElements () {
    this.$slider = $('[data-slider]', this)
    this.$sliderPagination = $('[data-slider-pagination]', this)
    this.$buttonNext = $('[data-slider-button="next"', this)
    this.$buttonPrev = $('[data-slider-button="prev"', this)
  }

  connectedCallback () {
    this.initSlider()
  }

  initSlider () {
    const { options, speed, autoplayDelay } = this.props
    const config = {
      slidesPerView: 2,
      slidesPerColumn: 2,
      speed: speed,
      loop: false,
      navigation: {
        nextEl: this.$buttonNext.get(0),
        prevEl: this.$buttonPrev.get(0)
      },
      autoplay: {
        delay: autoplayDelay
      },
      init: false,
      pagination: {
        el: this.$sliderPagination.get(0),
        type: 'bullets',
        dynamicBullets: true,
        clickable: true
      },

      breakpoints: {
        640: {
          // grid: false,
          slidesPerView: 4,
          slidesPerColumn: 1,
          spaceBetween: 40
        },
        991: {
          slidesPerView: 4,
          slidesPerGroup: 4,
          slidesPerColumn: 1
        }
      },
      keyboard: {
        enabled: true,
        onlyInViewport: true
      },
      watchOverflow: true,
      centerInsufficientSlides: true
    }
    if (options.autoplay && options.autoplaySpeed) {
      config.autoplay = {
        delay: options.autoplaySpeed
      }
    }

    this.slider = new Swiper(this.$slider.get(0), config)

    this.slider.init()

    this.slider.on('breakpoint', () => {
      this.slider.pagination.update()
    })
  }

  isMobile () {
    return window.matchMedia('(max-width: 1024px)').matches
  }
}

window.customElements.define('flynt-grid-icon-showcase', GridIconSlider, { extends: 'div' })
