import $ from 'jquery'
import 'lazysizes/plugins/object-fit/ls.object-fit'
import 'lazysizes/plugins/parent-fit/ls.parent-fit'
import 'lazysizes/plugins/respimg/ls.respimg'

class HeroMain extends window.HTMLDivElement {
  constructor (...args) {
    const self = super(...args)
    self.init()
    return self
  }

  init () {
    this.$ = $(this)
    this.resolveElements()
    this.bindFunctions()
    this.bindEvents()
  }

  resolveElements () {
  }

  bindFunctions () {
    this.scrollHandler = this.scrollHandler.bind(this)
  }

  bindEvents () {
    this.$.on('click', '[data-action="scrollNext"]', this.scrollHandler)
  }

  connectedCallback () {
  }

  scrollHandler (e) {
    e.preventDefault()
    const $currentElement = this.$.closest('.flyntComponent')

    this.scrollNext($currentElement)
  }

  scrollNext ($currentElement) {
    $('html, body').animate({
      scrollTop: ($currentElement.outerHeight())
    }, 650)
  }
}

window.customElements.define('flynt-hero-main', HeroMain, { extends: 'div' })
