import './scripts/publicPath'
import 'console-polyfill'
import 'normalize.css/normalize.css'
import './main.scss'
import $ from 'jquery'

import installCE from 'document-register-element/pony'

window.jQuery = $

window.lazySizesConfig = window.lazySizesConfig || {}
window.lazySizesConfig.preloadAfterLoad = true
require('lazysizes')

$(document).ready(function () {
  $('.floating-label').each((i, el) => {
    const input = $(el).find('.input')
    $(el).addClass('loaded')
    input.on('focus', () => {
      $(el).addClass('focus')
    }).on('blur', () => {
      $(el).removeClass('focus')
    })
    function checkInput () {
      if (input.val().length > 0) {
        $(el).addClass('floated')
      } else {
        $(el).removeClass('floated')
      }
    }
    checkInput()
    input.on('change', checkInput)
  })
})

installCE(window, {
  type: 'force',
  noBuiltIn: true
})

function importAll (r) {
  r.keys().forEach(r)
}

importAll(require.context('../Components/', true, /\/script\.js$/))
