import $ from 'jquery'

class AccordionDefault extends window.HTMLDivElement {
  constructor (...args) {
    const self = super(...args)
    self.init()
    return self
  }

  init () {
    this.$ = $(this)
    this.bindFunctions()
    this.bindEvents()
    this.resolveElements()
  }

  bindFunctions () {
    this.togglePanel = this.togglePanel.bind(this)
  }

  bindEvents () {
    this.$.on('click', '[aria-controls]', this.togglePanel)
  }

  resolveElements () {
    this.$panel = $('.panel-trigger', this)
  }

  togglePanel (e) {
    const $currentPanel = $(e.currentTarget)

    if ($currentPanel.attr('aria-expanded') === 'true') {
      $currentPanel.attr('aria-expanded', 'false')
      $currentPanel.next().attr('aria-hidden', 'true').slideUp()
    } else {
      this.$panel.attr('aria-expanded', 'false')
      this.$panel.next().attr('aria-hidden', 'true').slideUp()
      $currentPanel.attr('aria-expanded', 'true')
      $currentPanel.next().attr('aria-hidden', 'false').slideDown()
    }
  }
}

window.customElements.define('flynt-accordion-default', AccordionDefault, { extends: 'div' })
