import $ from 'jquery'

class ListJobs extends window.HTMLDivElement {
  constructor (...args) {
    const self = super(...args)
    self.init()
    return self
  }

  init () {
    this.$ = $(this)
    this.props = this.getInitialProps()
    this.resolveElements()
    this.bindFunctions()
    this.bindEvents()
    this.checkForUpdates()
  }

  resolveElements () {
    this.$filters = $('[data-action="filter"] [data-department]', this)
    this.$showContainers = $('[data-action="show"] [data-department]', this)
  }

  getInitialProps () {
    return {
      updatedAt: this.$.data('updatedAt')
    }
  }

  bindFunctions () {
    this.onFilter = this.onFilter.bind(this)
  }

  bindEvents () {
    this.$.on('click', '[data-action="filter"] [data-department]', this.onFilter)
  }

  onFilter (e) {
    e.preventDefault()
    const target = e.currentTarget
    const $target = $(target)
    if ($target.data('status') !== 'active') {
      this.activateFilter(target)
      this.showDepartment($target.data('department'))
    }
  }

  activateFilter (department) {
    this.$filters.each((i, filter) => {
      const $filter = $(filter)
      $filter.attr('data-status', (department === filter) || (department === $filter.data('department')) ? 'active' : 'inactive')
    })
  }

  showDepartment (department) {
    this.$showContainers.each((i, departmentWrapper) => {
      const $departmentWrapper = $(departmentWrapper)
      $departmentWrapper.prop('hidden', (department !== '') && ($departmentWrapper.data('department') !== department))
    })
  }

  checkForUpdates () {
    const { updatedAt } = this.props
    const url = new URL(window.location)
    url.searchParams.append('contentOnly', 1)

    $.ajax({
      url: url
    }).then(
      response => {
        const $html = $(`<div>${response}</div>`)
        const $component = $('[is="flynt-list-jobs"]', $html).first()
        const lastUpdatedAt = $component.data('updatedAt')

        if (lastUpdatedAt !== updatedAt) {
          this.$.html($component.html())
          this.resolveElements()
        }
      },
      response => {
        console.error(response)
      }
    )
  }
}

window.customElements.define('flynt-list-jobs', ListJobs, { extends: 'div' })
