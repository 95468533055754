import $ from 'jquery'
import Swiper from 'swiper/bundle'
import 'swiper/swiper-bundle.css'
import Rellax from 'rellax'

const $window = $(window)

let rellax, initialized

class SliderTextLogo extends window.HTMLDivElement {
  constructor (...args) {
    const self = super(...args)
    self.init()
    return self
  }

  init () {
    this.$ = $(this)
    this.props = this.getInitialProps()
    this.resolveElements()
    this.bindFunctions()
  }

  getInitialProps () {
    let data = {}
    try {
      data = JSON.parse($('script[type="application/json"]', this).text())
    } catch (e) {}
    return data
  }

  bindFunctions () {
    this.setParallax = this.setParallax.bind(this)
  }

  resolveElements () {
    this.$slider = $('.swiper-container', this)
  }

  connectedCallback () {
    this.initParallax()
    this.initSlider()
  }

  initSlider () {
    const { options } = this.props
    const config = {
      effect: 'fade',
      fadeEffect: {
        crossFade: true
      },
      a11y: options.a11y,
      slidesPerView: 'auto',
      autoplay: {
        delay: 2000
      },
      speed: 1000
    }
    this.sliderLeft = new Swiper(this.$slider.get(0), config)
    this.sliderRight = new Swiper(this.$slider.get(1), config)
  }

  initParallax () {
    if (!initialized) {
      initialized = true
      this.setParallax()
      $window.on('resize', this.setParallax)
    }
  }

  setParallax () {
    if (this.isMobile()) {
      if (rellax) {
        rellax.destroy()
        rellax = false
      }
    } else {
      if (!rellax && !this.isMobile()) {
        rellax = new Rellax('[data-slidertextlogo-parallax]', {
          speed: -0.85,
          center: true,
          percentage: 0.5
        })
      } else if (rellax) {
        rellax.refresh()
      }
    }
  }

  isMobile () {
    return window.matchMedia('(max-width: 1024px)').matches
  }
}

window.customElements.define('flynt-slider-text-logo', SliderTextLogo, { extends: 'div' })
