import $ from 'jquery'
import 'core-js/es/object/assign'
import Headroom from 'headroom.js'

class NavigationMain extends window.HTMLElement {
  constructor (...args) {
    const self = super(...args)
    self.init()
    return self
  }

  init () {
    this.$ = $(this)
    this.state = this.getInitialState()
    this.bindFunctions()
    this.bindEvents()
    this.resolveElements()
  }

  getInitialState () {
    return {
      touchStartTriggered: false
    }
  }

  bindFunctions () {
    this.onFocus = this.onFocus.bind(this)
    this.onBlur = this.onBlur.bind(this)
    this.onTouchStart = this.onTouchStart.bind(this)
    this.onTouchStartBody = this.onTouchStartBody.bind(this)
    this.onMouseEnter = this.onMouseEnter.bind(this)
    this.onMouseLeave = this.onMouseLeave.bind(this)
  }

  bindEvents () {
    this.$.on('focus', '[aria-controls]', this.onFocus)
    this.$.on('blur', '[aria-controls]', this.onBlur)
    this.$.on('touchstart', '[aria-controls] .menu-link', this.onTouchStart)
    this.$.on('mouseenter', '[aria-controls]', this.onMouseEnter)
    this.$.on('mouseleave', '[aria-controls]', this.onMouseLeave)
    $('body').on('touchstart', this.onTouchStartBody)
  }

  resolveElements () {
    this.$wrapper = $('[data-model="wrapper"]', this)
    this.$items = $('[aria-controls]', this)
    this.$dropdowns = $('[aria-labelledby]', this)
  }

  connectedCallback () {
    const headroom = new Headroom(this.$wrapper.get(0), {
      offset: 50, // = navigation padding top
      tolerance: 0, // or { down: 0, up: 0 }
      classes: {
        initial: 'headroom',
        pinned: 'headroom-isPinned',
        unpinned: 'headroom-isUnpinned',
        top: 'headroom-isTop',
        notTop: 'headroom-isNotTop',
        bottom: 'headroom-isBottom',
        notBottom: 'headroom-isNotBottom'
      },
      onPin: () => {
        this.$wrapper.addClass('headroom-pinned')
      },
      onUnpin: () => {
        this.closeDropdown()
        this.$wrapper.removeClass('headroom-pinned')
      },
      onTop: () => {
        this.$wrapper.removeClass('headroom-pinned')
      }
    })
    headroom.init()
  }

  onFocus (e) {
    const $target = $(e.currentTarget)
    this.openDropdown($target)
  }

  onBlur (e) {
    const $relatedTarget = $(e.relatedTarget)
    if (!$relatedTarget.closest('[aria-controls]').length) {
      this.closeDropdown()
    }
  }

  onTouchStart (e) {
    const $target = $(e.currentTarget)
    const $item = $target.closest('[aria-controls]')
    if ($item.attr('aria-expanded') === 'false') {
      e.preventDefault()
      this.openDropdown($item)
    }
    this.state.touchStartTriggered = true
  }

  onTouchStartBody (e) {
    if (!this.state.touchStartTriggered) {
      this.closeDropdown()
    }

    this.state.touchStartTriggered = false
  }

  onMouseEnter (e) {
    this.openDropdown($(e.currentTarget))
  }

  onMouseLeave () {
    this.closeDropdown()
  }

  openDropdown ($item) {
    if ($item.attr('aria-expanded') === 'true') {
      return
    }

    const targetId = $item.attr('aria-controls')
    const $dropdown = this.$dropdowns.filter(`[aria-labelledby="${targetId}"]`)

    this.closeDropdown()

    this.$wrapper.addClass('dropdownOpen')
    $item.attr('aria-expanded', 'true')
    $dropdown.attr('aria-hidden', 'false')
  }

  closeDropdown () {
    this.$wrapper.removeClass('dropdownOpen')
    this.$items.attr('aria-expanded', 'false')
    this.$dropdowns.attr('aria-hidden', 'true')
  }
}

window.customElements.define('flynt-navigation-main', NavigationMain, {
  extends: 'nav'
})
