import $ from 'jquery'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

class NavigationBurger extends window.HTMLElement {
  constructor (...args) {
    const self = super(...args)
    self.init()
    return self
  }

  init () {
    this.$ = $(this)
    this.bindFunctions()
    this.bindEvents()
    this.resolveElements()
  }

  bindFunctions () {
    this.triggerMenu = this.triggerMenu.bind(this)
    this.onTriggerSubmenu = this.onTriggerSubmenu.bind(this)
  }

  bindEvents () {
    this.$.on('click', '[data-toggle-menu]', this.triggerMenu)
    this.$.on('click', '[aria-controls]', this.onTriggerSubmenu)
  }

  resolveElements () {
    this.$menuBox = $('.menu', this)
    this.$menuButton = $('.hamburger', this)
    this.$items = $('[aria-controls]', this)
    this.$dropdowns = $('[aria-labelledby]', this)
  }

  connectedCallback () {}

  triggerMenu (e) {
    this.$.toggleClass('flyntComponent-menuIsOpen')
    this.$menuButton.attr('aria-expanded', this.$menuButton.attr('aria-expanded') === 'false' ? 'true' : 'false')
    if (this.$.hasClass('flyntComponent-menuIsOpen')) {
      disableBodyScroll(this.$menuBox.get(0))
    } else {
      enableBodyScroll(this.$menuBox.get(0))
    }
  }

  onTriggerSubmenu (e) {
    e.preventDefault()
    const $target = $(e.currentTarget)
    this.openDropdown($target)
  }

  openDropdown ($item) {
    if ($item.attr('aria-expanded') === 'true') {
      this.closeDropdown()
    } else {
      const targetId = $item.attr('aria-controls')
      const $dropdown = this.$dropdowns.filter(`[aria-labelledby="${targetId}"]`)

      this.closeDropdown()

      $item.attr('aria-expanded', 'true')
      $dropdown.attr('aria-hidden', 'false').slideDown()
    }
  }

  closeDropdown () {
    this.$items.attr('aria-expanded', 'false')
    this.$dropdowns.attr('aria-hidden', 'true').slideUp()
  }
}

window.customElements.define('flynt-navigation-burger', NavigationBurger, {
  extends: 'nav'
})
