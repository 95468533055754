import $ from 'jquery'
import Rellax from 'rellax'

const $window = $(window)

let rellaxSlow, rellaxFast, initialized

class GridPostTeasers extends window.HTMLDivElement {
  constructor (...args) {
    const self = super(...args)
    self.init()
    return self
  }

  init () {
    this.$ = $(this)
    this.bindFunctions()
  }

  bindFunctions () {
    this.setParallax = this.setParallax.bind(this)
  }

  connectedCallback () {
    this.initParallax()
  }

  initParallax () {
    if (!initialized) {
      initialized = true
      this.setParallax()
      $window.on('resize', this.setParallax)
    }
  }

  setParallax () {
    if (this.isMobile()) {
      if (rellaxFast) {
        rellaxFast.destroy()
        rellaxFast = false
      }

      if (rellaxSlow) {
        rellaxSlow.destroy()
        rellaxSlow = false
      }
    } else if (!this.isMobile()) {
      if (!rellaxFast) {
        if ($('[data-gridpostteasers-parallax-fast]').length > 0) {
          rellaxFast = new Rellax('[data-gridpostteasers-parallax-fast]', {
            speed: -0.5,
            center: true,
            percentage: 0.3
          })
        }
      } else if (rellaxFast) {
        rellaxFast.refresh()
      }

      if (!rellaxSlow) {
        if ($('[data-gridpostteasers-parallax-slow]').length > 0) {
          rellaxSlow = new Rellax('[data-gridpostteasers-parallax-slow]', {
            speed: -0.85,
            center: true,
            percentage: 0.5
          })
        }
      } else if (rellaxSlow) {
        rellaxSlow.refresh()
      }
    }
  }

  isMobile () {
    return window.matchMedia('(max-width: 1024px)').matches
  }
}

window.customElements.define('flynt-grid-post-teasers', GridPostTeasers, { extends: 'div' })
