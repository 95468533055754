import $ from 'jquery'

class GridPosts extends window.HTMLDivElement {
  constructor (...args) {
    const self = super(...args)
    self.init()
    return self
  }

  init () {
    this.$ = $(this)
    this.resolveElements()
    this.bindFunctions()
    this.bindEvents()
  }

  resolveElements () {
    this.$window = $(window)
    this.$document = $(document)
    this.$grid = $('.grid', this)
  }

  bindFunctions () {
    this.onLoadMore = this.onLoadMore.bind(this)
  }

  bindEvents () {
    this.$.on('click', '[data-action="loadMore"]', this.onLoadMore)
  }

  onLoadMore (e) {
    e.preventDefault()

    const $currentTarget = $(e.currentTarget)
    const url = $currentTarget.data('url')
    const offset = $currentTarget.parent('div').prev('ul').children().length
    const taxonomies = $currentTarget.data('taxonomies')
    const itemsLenght = $currentTarget.data('itemslenght')

    $.ajax({
      url: url,
      data: {
        offset: offset,
        taxonomies: taxonomies
      }
    }).then(
      response => {
        const $html = $(response)
        if ((itemsLenght - offset) <= 6) {
          $(e.currentTarget).parent().addClass('hidden')
        }

        this.$grid.append($html)
      },
      response => {
        console.error(response)
      }
    )
  }
}

window.customElements.define('flynt-grid-posts', GridPosts, { extends: 'div' })
