import $ from 'jquery'

class BlockVideoOembed extends window.HTMLDivElement {
  constructor (...args) {
    const self = super(...args)
    self.init()
    return self
  }

  init () {
    this.$ = $(this)
    this.resolveElements()
    this.bindFunctions()
    this.bindEvents()
  }

  resolveElements () {
    this.$posterImage = $('.figure-image', this)
    this.$videoPlayer = $('.video-player', this)
    this.$videoContainer = $('.video', this)
    this.$iframe = $('iframe', this)
  }

  bindFunctions () {
    this.loadVideo = this.loadVideo.bind(this)
    this.videoIsLoaded = this.videoIsLoaded.bind(this)
  }

  bindEvents () {
    this.$.one('click', '.video-playButton', this.loadVideo)
    this.$videoPlayer.on('DOMSubtreeModified', this.consentExternalMedia.bind(this))
  }

  connectedCallback () {
    setTimeout(() => {
      const $cookie = this.$videoContainer.find('.BorlabsCookie')
      if ($cookie.length > 0) {
        this.$videoContainer.addClass('video-player--blockerVisible')
      }
    }, 1000)
  }

  consentExternalMedia () {
    const $iframeElem = this.$videoContainer.find('iframe')
    console.log($iframeElem)
    if ($iframeElem.length > 0) {
      this.$videoContainer.removeClass('video-player--blockerVisible')
      this.$iframe = $iframeElem
    }
  }

  loadVideo () {
    this.$iframe.attr('src', this.$iframe.data('src'))
    this.$iframe.one('load', this.videoIsLoaded.bind(this))
    this.$videoPlayer.addClass('video-player--isLoading')
  }

  videoIsLoaded () {
    this.$videoPlayer.addClass('video-player--isLoaded')
    this.$posterImage.addClass('figure-image--isHidden')
  }
}

window.customElements.define('flynt-block-video-oembed', BlockVideoOembed, { extends: 'div' })
