import $ from 'jquery'
import Rellax from 'rellax'

const $window = $(window)

let rellax, initialized

class GridIconTeasers extends window.HTMLDivElement {
  constructor (...args) {
    const self = super(...args)
    self.init()
    return self
  }

  init () {
    this.$ = $(this)
    this.bindFunctions()
  }

  bindFunctions () {
    this.setParallax = this.setParallax.bind(this)
  }

  connectedCallback () {
    this.initParallax()
  }

  initParallax () {
    if (!initialized) {
      initialized = true
      this.setParallax()
      $window.on('resize', this.setParallax)
    }
  }

  setParallax () {
    if (this.isMobile()) {
      if (rellax) {
        rellax.destroy()
        rellax = false
      }
    } else {
      if (!rellax && !this.isMobile()) {
        rellax = new Rellax('[data-gridiconteasers-parallax]', {
          speed: -0.85,
          center: true,
          percentage: 0.5
        })
      } else if (rellax) {
        rellax.refresh()
      }
    }
  }

  isMobile () {
    return window.matchMedia('(max-width: 1024px)').matches
  }
}

window.customElements.define('flynt-grid-icon-teasers', GridIconTeasers, { extends: 'div' })
