import 'lazysizes/plugins/object-fit/ls.object-fit'
import 'lazysizes/plugins/parent-fit/ls.parent-fit'
import 'lazysizes/plugins/respimg/ls.respimg'

import $ from 'jquery'
import Swiper from 'swiper/bundle'
import 'swiper/swiper-bundle.css'
class SliderBlockImage extends window.HTMLDivElement {
  constructor (...args) {
    const self = super(...args)
    self.init()
    return self
  }

  init () {
    this.$ = $(this)
    this.props = this.getInitialProps()
    this.resolveElements()
    this.bindFunctions()
  }

  getInitialProps () {
    let data = {}
    try {
      data = JSON.parse($('script[type="application/json"]', this).text())
    } catch (e) {}
    return data
  }

  bindFunctions () {
  }

  resolveElements () {
    this.$slider = $('.swiper-container', this)
  }

  connectedCallback () {
    this.initSlider()
  }

  initSlider () {
    const { options } = this.props
    const config = {
      /*  effect: 'fade',
      fadeEffect: {
          crossFade: true
      },  */
      slidesPerView: 1,
      speed: 1000,
      slidesPerGroup: 1,
      /*
      breakpoints: {
          // when window width is >= 320px
          320: {
            slidesPerView: 2,
            spaceBetween: 20
          },
          // when window width is >= 480px
          480: {
            slidesPerView: 3,
            spaceBetween: 30
          },
          // when window width is >= 640px
          640: {
            slidesPerView: 4,
            spaceBetween: 40,
            slidesPerGroup: 4
          }
      },
          */
      keyboard: {
        enabled: true,
        onlyInViewport: true
      },
      watchOverflow: true,
      centerInsufficientSlides: true
    }
    if (options.autoplay && options.autoplaySpeed) {
      config.autoplay = {
        delay: options.autoplaySpeed
      }
    }

    this.slider = new Swiper(this.$slider.get(0), config)
  }

  isMobile () {
    return window.matchMedia('(max-width: 1024px)').matches
  }
}

window.customElements.define('flynt-block-image-slider', SliderBlockImage, { extends: 'div' })
